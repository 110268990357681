/*
 * LocalStorageKeys
 * Centralized collection of keys used by local storage
 */

enum LocalStorageKeys {
  AdjustmentIncrement = "adjustmentIncrement",
  DefaultTimerMode = "defaultTimerMode",
  FontSize = "fontSize",
  IsMicAccessGranted = "isMicAccessGranted",
  Locale = "locale",
  Theme = "theme",
  ZeroAtDistance = "zeroAtDistance",
  Units = "units",
}

export default LocalStorageKeys;
