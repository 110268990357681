/**
 * Defaults
 * Default data for form fields
 */

const MilDotCalcDefaultValues = {
  milSize: undefined,
  physicalSize: undefined,
  distance: undefined,
  shouldShowResultsModal: false,
  isValid: false,
  errors: [],
  result: undefined,
};

export default MilDotCalcDefaultValues;
