/**
 * Direction
 * Enumeration of directions for user selection
 */
export enum Direction {
  Up = "up",
  Down = "down",
  Left = "left",
  Right = "right",
}
